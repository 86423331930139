import * as React from 'react';

import { useScroll } from './useScroll';

/**
 * @name useStoreFixedScroll
 * @description A hook that will return true if we have scrolled past the
 * breakpoint for the dropdown menu to become sticky in the store pages.
 */
const useStoreFixedScroll = (scrollPast: number) => {
  const [isSticky, setIsSticky] = React.useState(false);

  // Handlers
  const onScroll = () => {
    const scrollTop = window.scrollY;
    const isSticky = scrollTop > scrollPast;

    setIsSticky(isSticky);
  };

  // Hooks
  useScroll(onScroll);

  return { isSticky };
};

export { useStoreFixedScroll };
